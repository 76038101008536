function hubspotTooltip(){
  var $email = $('.hs-email .input'),
      $emailInput = $('.hs-email .input input'),
      $emailError = $('.hs-email .hs-error-msg');

      $email.append('<div class="custom-error" data-toggle="tooltip" title="We require a corporate email address, if your company does not have one please email marketing@chargebacks911.com">?</div>')

  $('[data-toggle="tooltip"]').tooltip()

  setInterval(function(){
    var text = "";
    
    if( $('.hs-email .hs-error-msg').length){
      text = $('.hs-email .hs-error-msg').html();
      if(text.indexOf("Please enter a different email address.") >= 0) {
        $('.custom-error').addClass('invalid');
      } else {
        $('.custom-error').removeClass('invalid');
      }
    }
  }, 100)
}
