$(document).ready(function() {

  $('.js-countdown').each(function() {
    var that = $(this),
        e = {},
        dateStamp = $(this).data('time');

    e.event = new Date(dateStamp).getTime();
    var n = setInterval(function() {
      e.now = (new Date).getTime();
      var i = e.event - e.now,
        a = String('00' + Math.floor(i / 864e5)).slice(-2),
        s = String('00' + Math.floor(i % 864e5 / 36e5)).slice(-2),
        d = String('00' + Math.floor(i % 36e5 / 6e4)).slice(-2),
        t = String('00' + Math.floor(i % 6e4 / 1e3)).slice(-2);

      that.html('\n<div class="time-wrapper">\n<div class="time days">' + a + '</div>\n        <div class="label">Days</div>\n      </div>\n      <div class="time-wrapper">\n        <div class="time hours">' + s + '</div>\n        <div class="label">Hours</div>\n      </div>\n      <div class="time-wrapper">\n        <div class="time minutes">' + d + '</div>\n        <div class="label">Minutes</div>\n      </div>\n      <div class="time-wrapper">\n        <div class="time seconds">' + t + '</div>\n        <div class="label">Seconds</div>\n      </div>\n')
    }, 1e3)


  })



  $('.owl-carousel').each(function(){

    $(this).owlCarousel({
      loop:true,
      margin: 30,
      dots: true,
      center: true,
      autoplay: true,
      responsive:{
        0:{
            items:1
        },
        767:{
            items:3
        }
      }
    })


  })


})
