$(document).ready(function() {

	$('.page-banner-container').each(function() {
		var that = $(this);
		var offset = that.offset();
		var offsetTotal = offset.top;

		$(window).scrollTop() >= offsetTotal ? $("body").addClass("stuck") : $("body").removeClass("stuck"),

			$(window).scroll(function() {

				$(window).scrollTop() >= offsetTotal ? $("body").addClass("stuck") : $("body").removeClass("stuck")

			})

	})

})
